import { Link as StandardLink, Typography, TypographyProps } from "@mui/material";
import { ReactNode } from "react";
import { CellProps } from "react-table";
import Tooltip from "../Tooltip";

interface ITableLinkCallbackCellProps<D extends object = Record<string, unknown>> extends CellProps<D>, Pick<TypographyProps, "variant"> {
    /**
     * Cell value will be used as link text, falling back to children when the cell value is nullish.
     *
     * Additionally, when the value differs from the provided children, the children will be renderend outside the button element so that
     * additional content can be rendered
     *
     * @type {ReactNode}
     * @memberof ITableLinkCellProps
     */
    children?: ReactNode;
    onClick?: (index: number) => void;
    className?: string;
}

/**
 * Renders a link calling the onClick callback prop.
 *
 * A regular <a> tag is rendered that does not redirect but calls the onClick callback with row index.
 *
 * @template D
 * @param {ITableLinkCallbackCellProps<D>} {
 *     value,
 *     children,
 *     onClick = () => {},
 *     ...textCellProps
 * }
 * @returns
 */
const TableLinkCallbackCell = <D extends object = Record<string, unknown>>({
    value,
    children,
    onClick = () => {},
    row,
    className,
    variant,
}: ITableLinkCallbackCellProps<D>) => {
    const linkLabel = value ?? children;
    return (
        <div className={className}>
            <Tooltip title={linkLabel} placement="right" onlyOverflowing>
                <Typography variant={variant} noWrap>
                    <StandardLink onClick={() => onClick(row.index)} sx={{ cursor: "pointer" }} fontSize="inherit" variant="body2">
                        {linkLabel}
                    </StandardLink>
                </Typography>
            </Tooltip>
            {linkLabel !== children ? children : null}
        </div>
    );
};

export default TableLinkCallbackCell;
